import {
    ADD_SUBSCRIBER_ERROR,
    ADD_SUBSCRIBER_PENDING,
    ADD_SUBSCRIBER_SUCCESS,
    DELETE_SUBSCRIBER_ERROR,
    DELETE_SUBSCRIBER_PENDING,
    DELETE_SUBSCRIBER_SUCCESS,
    FETCH_SUBSCRIBERS_ERROR,
    FETCH_SUBSCRIBERS_PENDING,
    FETCH_SUBSCRIBERS_SUCCESS,
    UPDATE_SUBSCRIBERS_ERROR,
    UPDATE_SUBSCRIBERS_PENDING,
    UPDATE_SUBSCRIBERS_SUCCESS,
    SUBSCRIBER_CLEAN
} from "./subscribers.actionTypes";

const initialState = {
    pending: false,
    subscribers: [],
    totalItemCount: 1,
    error: {
        data: []
    }
};

export function subscribersReducer(state = initialState, action) {
    switch (action.type) {
        case SUBSCRIBER_CLEAN:
            return initialState;
        case FETCH_SUBSCRIBERS_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case FETCH_SUBSCRIBERS_SUCCESS:
            return {
                ...state,
                pending: false,
                subscribers: action.payload.subscribers,
                totalItemCount: action.payload.count,
                error: initialState.error
            };
        case FETCH_SUBSCRIBERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error.data
            };
        case UPDATE_SUBSCRIBERS_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case UPDATE_SUBSCRIBERS_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                subscribers: state.subscribers.map(item => {
                    if (action.payload.subscriber.id === item.id)
                        item = action.payload.subscriber;
                    return item
                }),
            };
        case UPDATE_SUBSCRIBERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error.data
            };
        case ADD_SUBSCRIBER_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case ADD_SUBSCRIBER_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                subscribers: [action.payload.newItem, ...state.subscribers],
                totalItemCount: state.totalItemCount + 1,
                pending: false
            };
        case ADD_SUBSCRIBER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error.data
            };
        case DELETE_SUBSCRIBER_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case DELETE_SUBSCRIBER_SUCCESS:
            return {
                ...state,
                error: initialState.error,
                subscribers: state.subscribers.filter(item => item.id !== action.payload.item),
                totalItemCount: state.totalItemCount - 1,
                pending: false
            };
        case DELETE_SUBSCRIBER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error.data
            };
        default:
            return state;
    }
}

export default subscribersReducer;