import {
    USER_LOGIN_ERROR,
    USER_LOGIN_PENDING,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_UPDATE_ERROR,
    USER_UPDATE_PENDING,
    USER_UPDATE_SUCCESS,
    USER_GET_ERROR,
    USER_GET_PENDING,
    USER_GET_SUCCESS,
    LOGIN_AS_USER_ERROR,
    LOGIN_AS_USER_PENDING,
    LOGIN_AS_USER_SUCCESS,
    LOGOUT_AS_USER_ERROR,
    LOGOUT_AS_USER_PENDING,
    LOGOUT_AS_USER_SUCCESS,
    PROFILE_GET_SUCCESS,
    PROFILE_GET_PENDING,
    PROFILE_GET_ERROR
} from "./auth.actionTypes";

const initialState = {
    pending: false,
    user: null,
    token: null,
    profile: null,
    isLogin: false,
    expiry: null,
    error: {},
    adminUser: null,
    adminToken: null,
    adminProfile: null,
    AdminExpiry: null,
};
export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case LOGOUT_AS_USER_PENDING:
            return {
                ...state,
                pending: true,
                error: {}
            };
        case LOGOUT_AS_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                user: state.adminUser,
                token: state.adminToken,
                profile: state.adminProfile,
                expiry: state.AdminExpiry,
                adminUser: null,
                adminToken: null,
                adminProfile: null,
                AdminExpiry: null,
            };
        case LOGOUT_AS_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        case LOGIN_AS_USER_PENDING:
            return {
                ...state,
                pending: true,
                error: {}
            };
        case LOGIN_AS_USER_SUCCESS:
            const {user, token, expiry, profile} = action.payload;
            return {
                ...state,
                adminUser: state.user,
                adminToken: state.token,
                adminProfile: state.profile,
                AdminExpiry: state.expiry,
                user,
                token,
                expiry,
                profile,
                pending: false,
            };
        case LOGIN_AS_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        case USER_GET_PENDING:
            return {
                ...state,
                pending: true,
            };
        case USER_GET_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                pending: false,
                error: {}

            };
        case USER_GET_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        case USER_LOGIN_SUCCESS: {
            const {user, token, expiry, profile} = action.payload;
            return {
                ...state,
                isLogin: true,
                user,
                token,
                expiry,
                profile,
                pending: false,
                error: {}
            };
        }
        case USER_LOGIN_PENDING: {
            return {
                ...state,
                pending: true,
                error: {}
            };
        }
        case USER_LOGIN_ERROR: {
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        }

        case USER_UPDATE_SUCCESS: {
            const {user, profile} = action.payload;
            return {
                user: user,
                profile: profile,
                ...state,
            }
        }
        case USER_UPDATE_PENDING: {
            return {
                ...state,
                error: {},
                pending: true
            }
        }
        case USER_UPDATE_ERROR: {
            return {
                ...state,
                pending: false,
                error: action.payload.error
            }
        }
        case PROFILE_GET_SUCCESS: {
            const {profile} = action.payload;
            return {
                ...state,
                profile: profile,
            }
        }
        case PROFILE_GET_PENDING: {
            return {
                ...state,
                error: {},
                pending: true
            }
        }
        case PROFILE_GET_ERROR: {
            return {
                ...state,
                pending: false,
                error: action.payload.error
            }
        }
        case USER_LOGOUT: {
            return initialState
        }
        default:
            return state;
    }
}

