import {ADD_FILTER, ADD_SEARCH} from "./filter.actionTypes";

const initialState = {
    author: '',
    step: '',
    search: '',
    is_archived: false

};
const filterReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case ADD_FILTER: {
            return {
                ...state,
                author: payload.author,
                step: payload.step,
                is_archived: payload.is_archived
            };
        }
        case ADD_SEARCH: {
            return {
                ...state,
                search: payload.search
            };
        }
        default:
            return state;
    }
};
export default filterReducer;