export const FETCH_FORMS_PENDING = 'FETCH_FORMS_PENDING';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_ERROR = 'FETCH_FORMS_ERROR';

export const UPDATE_FORMS_PENDING = 'UPDATE_FORMS_PENDING';
export const UPDATE_FORMS_SUCCESS = 'UPDATE_FORMS_SUCCESS';
export const UPDATE_FORMS_ERROR = 'UPDATE_FORMS_ERROR';

export const DELETE_FORMS_PENDING = 'DELETE_FORMS_PENDING';
export const DELETE_FORMS_SUCCESS = 'DELETE_FORMS_SUCCESS';
export const DELETE_FORMS_ERROR = 'DELETE_FORMS_ERROR';

export const ADD_FORMS_PENDING = 'ADD_FORMS_PENDING';
export const ADD_FORMS_SUCCESS = 'ADD_FORMS_SUCCESS';
export const ADD_FORMS_ERROR = 'ADD_FORMS_ERROR';

export  const FORM_CLEAN = 'FORM_CLEAN';

