import {createSelector} from "reselect";

export const auth = state => state.auth;

export const user = createSelector(
    [auth],
    (auth) => auth.user
);
export const token = createSelector(
    [auth],
    (auth) => auth.token
);
export const profile = createSelector(
    [auth],
    (auth) => auth.profile
);
export const isLogin = createSelector(
    [auth],
    (auth) => auth.isLogin
);
export const pending = createSelector(
    [auth],
    (auth) => auth.pending
);
export const error = createSelector(
    [auth],
    (auth) => auth.error
);

export const adminUser = createSelector(
    [auth],
    (auth) => auth.adminUser
);