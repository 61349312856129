import {
    DELETE_CAMPAIGN_ERROR,
    DELETE_CAMPAIGN_PENDING,
    DELETE_CAMPAIGN_SUCCESS,
    FETCH_CAMPAIGNS_ERROR,
    FETCH_CAMPAIGNS_PENDING,
    FETCH_CAMPAIGNS_SUCCESS,
    UPDATE_CAMPAIGN_ERROR,
    UPDATE_CAMPAIGN_PENDING,
    UPDATE_CAMPAIGN_SUCCESS,
    CAMPAIGN__CLEAN,
    CAMPAIGN_SEND_ERROR,
    CAMPAIGN_SEND_PENDING,
    CAMPAIGN_SEND_SUCCESS
} from "./campaigns.actionTypes";

const initialState = {
    pending: false,
    send_pending: false,
    campaigns: [],
    campaign: {
        name: '',
        id: null,
        step: 1,
        sender_name: '',
        owner: '',
        sender_email: '',
        html_content: '',
        json_content: '',
        plain_text: '',
        html_editor: "false",
        received_list: [],
        subject: '',
        configure: '',
        google_track:'',
        schedule_date: new Date(),
        googleAnalytics: true,
        resend: false,
        is_sent: false,
        creator: null,
    },
    totalItemCount: 1,
    error: {
        data: [],
    }
};

export function campaignsReducer(state = initialState, action) {
    const {payload, type} = action;
    switch (type) {
        case CAMPAIGN_SEND_SUCCESS:
            return {
                ...state,
                campaigns: state.campaigns.map(item => item.id === payload.id ? payload.item : item),
                send_pending: false,
                error: initialState.error
            }
        case CAMPAIGN_SEND_PENDING:
            return {
                ...state,
                send_pending: true,
                error: initialState.error
            }
        case CAMPAIGN_SEND_ERROR:
            return {
                ...state,
                send_pending: false,
                error: payload.error

            }
        case CAMPAIGN__CLEAN:
            return initialState;
        case FETCH_CAMPAIGNS_PENDING:
            return {
                ...state,
                campaign: initialState.campaign,
                campaigns: initialState.campaigns,
                pending: true,
                error: initialState.error
            };
        case FETCH_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                pending: false,
                campaigns: action.payload.campaigns,
                totalItemCount: action.payload.count,
                error: initialState.error
            };
        case FETCH_CAMPAIGNS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        case UPDATE_CAMPAIGN_PENDING:
            return {
                ...state,
                campaign: initialState.campaign,
                pending: true,
                error: initialState.error
            };
        case UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaign: {...action.payload.campaign},
                pending: false,
                error: initialState.error
            };
        case UPDATE_CAMPAIGN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pending: false
            };
        case DELETE_CAMPAIGN_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaigns: state.campaigns.filter(item => item.id !== action.payload.item),
                pending: false,
                error: initialState.error
            };
        case DELETE_CAMPAIGN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pending: false,
            };
        default:
            return state;
    }
}

export default campaignsReducer;