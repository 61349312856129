export const FETCH_CAMPAIGNS_PENDING = 'FETCH_CAMPAIGNS_PENDING';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_ERROR = 'FETCH_CAMPAIGNS_ERROR';

export const UPDATE_CAMPAIGN_PENDING = 'UPDATE_CAMPAIGN_PENDING';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';

export const DELETE_CAMPAIGN_PENDING = 'DELETE_CAMPAIGN_PENDING';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';

export const CAMPAIGN__CLEAN = 'CAMPAIGN__CLEAN';

export const CAMPAIGN_SEND_PENDING = "CAMPAIGN_SEND_PENDING";
export const CAMPAIGN_SEND_SUCCESS = "CAMPAIGN_SEND_SUCCESS";
export const CAMPAIGN_SEND_ERROR = "CAMPAIGN_SEND_ERROR";