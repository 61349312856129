import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {isLogin} from "./redux/reducers/user/user.selector";
import {createStructuredSelector} from "reselect";
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Pages
const Login = React.lazy(() => import ('./views/Pages/Login'));
const Register = React.lazy(() => import ('./views/Pages/Register'));
const ResetPassword = React.lazy(() => import ('./views/Pages/ResetPassword'));
const ActiveEmail = React.lazy(() => import ('./views/Pages/ActiveEmail/ActiveEmail'));
const DefaultLayout = React.lazy(() => import ('./containers'));

class App extends Component {
    componentDidMount() {
        // let a = document.getElementsByTagName('html')[0]
        // a.dir='rtl';

    }

    render() {
        const {isLogin} = this.props;
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route
                            exact
                            path="/activate/:id/:token"
                            name="Login Page"
                            render={props => <ActiveEmail {...props}/>}/>
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/campaign/wizard/template/:id"*/}
                        {/*    name="temaplte Page"*/}
                        {/*    render={props => <TemplateEmail {...props}/>}/>*/}
                        <Route
                            exact
                            path="/login"
                            name="Login Page"
                            render={props => <Login {...props}/>}/>
                        <Route
                            exact
                            path="/register"
                            name="Register Page"
                            render={props => <Register {...props}/>}/>
                        <Route
                            path="/passwordreset/:email/:token"
                            name="Reset Password"
                            render={props => <ResetPassword {...props}/>}/>
                        <Route path="/" name="Home"
                               render={props => isLogin ? <DefaultLayout {...props}/> : <Login {...props}/>}/>
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isLogin: isLogin
});

export default connect(mapStateToProps)(App);
