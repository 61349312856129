import {
    ADD_FORMS_ERROR,
    ADD_FORMS_PENDING,
    ADD_FORMS_SUCCESS,
    DELETE_FORMS_ERROR,
    DELETE_FORMS_PENDING,
    DELETE_FORMS_SUCCESS,
    FETCH_FORMS_ERROR,
    FETCH_FORMS_PENDING,
    FETCH_FORMS_SUCCESS,
    UPDATE_FORMS_ERROR, UPDATE_FORMS_PENDING, UPDATE_FORMS_SUCCESS , FORM_CLEAN
} from "./forms.actionTypes";

const initialState = {
    pending: false,
    forms: [],
    totalItemCount: 1,
    error: {
        data: [],
    }
};

export function formsReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case FORM_CLEAN:
            return initialState;
        case FETCH_FORMS_PENDING:
            return {
                ...state,
                pending: true,
                forms: initialState.forms,
                error: initialState.error
            };
        case FETCH_FORMS_ERROR:
            return {
                ...state,
                error: payload.error,
                pending: false,
            };
        case FETCH_FORMS_SUCCESS:
            return {
                ...state,
                forms: payload.forms,
                totalItemCount: payload.count,
                pending: false,
            };
        case ADD_FORMS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_FORMS_SUCCESS:
            return {
                ...state,
                pending: false,
                forms: [payload.newItem, ...state.forms],
                totalItemCount:state.totalItemCount +1
            };
        case ADD_FORMS_ERROR:
            return {
                ...state,
                pending: false,
                error: payload.error
            };
        case DELETE_FORMS_SUCCESS:
            return {
                ...state,
                pending: false,
                forms: state.forms.filter(item => item.id !== payload.deletedId),
                totalItemCount:state.totalItemCount-1
            };
        case DELETE_FORMS_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case DELETE_FORMS_ERROR:
            return {
                ...state,
                pending: false,
                error: payload.error
            };
        case UPDATE_FORMS_ERROR:
            return {
                ...state,
                pending: false,
                error:  payload.error
            };
        case UPDATE_FORMS_PENDING:
            return {
                ...state,
                pending: true,
                error: initialState.error
            };
        case UPDATE_FORMS_SUCCESS:
            return {
                ...state,
                pending: false,
                error: initialState.error,
                forms: state.forms.map(item => {
                    if (payload.form.id === item.id)
                        item = action.payload.form;
                    return item
                }),
            };
        default:
            return state;
    }

}

export default formsReducer;