
export const FETCH_TOUR_SUCCESS = 'FETCH_TOUR_SUCCESS';
export const FETCH_TOUR_ERROR = 'FETCH_TOUR_ERROR';

export const SET_TOUR_READ = 'SET_TOUR_READ';
export const SET_TOUR_UNREAD = 'SET_TOUR_UNREAD';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';


