import {combineReducers} from "redux";
import filterReducer from "./filter/filter.reducer";
import campaignsReducer from './campaigns/campaigns.reducer'
import subscribersReducer from './subscribers/subscribers.reducer'
import formsReducer from './froms/forms.reducer'
import authReducer from "./user/auth.reducer";
import formStyleReducer from "./formStyle/formStyle.reducer"
import tourReducer from "./Tour/tour.reducer";
import sendingDomainReducer from "./sending_domains/sending_domain.reducer";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {persistReducer} from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['tour', 'auth', 'forms']
};


const rootReducer = combineReducers({
    filters: filterReducer, auth: authReducer, tour: tourReducer,
    campaigns: campaignsReducer, subscribers: subscribersReducer,
    forms: formsReducer, formStyle: formStyleReducer,
    sendingDomain: sendingDomainReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;