import {applyMiddleware, createStore} from "redux";
import {persistStore} from 'redux-persist';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import persistedReducer from "./reducers";


const middleware = [thunk, logger];

export const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware)
);
export const persistor = persistStore(store);

