export default {

    FETCH_SENDING_DOMAINS_START: "FETCH_SENDING_DOMAINS_START",
    FETCH_SENDING_DOMAINS_SUCCESS: "FETCH_SENDING_DOMAINS_SUCCESS",
    FETCH_SENDING_DOMAINS_FAILURE: "FETCH_SENDING_DOMAINS_FAILURE",

    CREATE_SENDING_DOMAINS_START: "CREATE_SENDING_DOMAINS_START",
    CREATE_SENDING_DOMAINS_SUCCESS: "CREATE_SENDING_DOMAINS_SUCCESS",
    CREATE_SENDING_DOMAINS_FAILURE: "CREATE_SENDING_DOMAINS_FAILURE",

    VALIDATE_SENDING_DOMAINS_START: "VALIDATE_SENDING_DOMAINS_START",
    VALIDATE_SENDING_DOMAINS_SUCCESS: "VALIDATE_SENDING_DOMAINS_SUCCESS",
    VALIDATE_SENDING_DOMAINS_FAILURE: "VALIDATE_SENDING_DOMAINS_FAILURE",

    REMOVE_SENDING_DOMAINS_START: "REMOVE_SENDING_DOMAINS_START",
    REMOVE_SENDING_DOMAINS_SUCCESS: "REMOVE_SENDING_DOMAINS_SUCCESS",
    REMOVE_SENDING_DOMAINS_FAILURE: "REMOVE_SENDING_DOMAINS_FAILURE",
    NEXT_PAGE:"NEXT_PAGE",
    PRE_PAGE:"PRE_PAGE",
    SET_LIMIT:"SET_LIMIT",
}