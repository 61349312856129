import {SET_CURRENT_PAGE, SET_TOUR_READ, SET_TOUR_UNREAD} from './tour.actionsTypes'

const initialState = {
    showCampaignsTour: false,
    showCampaignDetailsTour: false,
    showDashboardBegin:false,
    showTemplateDetailsTour: false,
    showSubscribersTour: false,
    showSubscriberTour: false,
    showSuccessTour: false,
    showConfirmationTour: false,
    accentColor: "#5cb7b7",
    showDashboardTour: false,
    currentTourToDisplay: 'showDashboardTour'
};


export function tourReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TOUR_READ:
            return {...state, [action.payload.item]: false};
        case SET_TOUR_UNREAD:
            return {...state, [action.payload.item]: true};
        case SET_CURRENT_PAGE:
            return {...state, currentTourToDisplay: action.payload};
        default:
            return state
    }
}

export default tourReducer