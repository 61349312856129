export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_PENDING = "USER_LOGIN_PENDING";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";


export const USER_LOGOUT = "USER_LOGOUT";


export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_PENDING = "USER_UPDATE_PENDING";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";

export const USER_GET_PENDING = "USER_GET_PENDING";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";

export const LOGIN_AS_USER_PENDING = "LOGIN_AS_USER_PENDING";
export const LOGIN_AS_USER_SUCCESS = "LOGIN_AS_USER_SUCCESS";
export const LOGIN_AS_USER_ERROR = "LOGIN_AS_USER_ERROR";

export const LOGOUT_AS_USER_PENDING = "LOGOUT_AS_USER_PENDING";
export const LOGOUT_AS_USER_SUCCESS = "LOGOUT_AS_USER_SUCCESS";
export const LOGOUT_AS_USER_ERROR = "LOGOUT_AS_USER_ERROR";

export const PROFILE_GET_PENDING = "PROFILE_GET_PENDING";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_ERROR = "PROFILE_GET_ERROR";
