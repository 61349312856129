import {ADD_FORMS_BUTTON_STYLE, ADD_FORMS_FIELDS_STYLE, ADD_FORMS_STYLE, ADD_FORMS_TEXT_STYLE} from "./formStyle.actionTypes";

const initialState = {
    //forms styles
    frmWidth: 250,
    brdWidth: 2,
    brdColor: '#a3a3a3',
    brdRadius: 10,
    // text styles
    titleColor: '#565656',
    titleSize: 20,
    textColor: '#29484f',
    textSize: 12,
    // fields styles
    filedBorderColor: "#c4c4c4",
    filedBorderRadius: 3,
    fieldBorderWidth: 2,
    fieldBgColor: '#ffffff',
    fieldTextColor: "#000000",
    // button styles
    btnTextColor:'#ffffff',
    btnBgColor:"#aa2500",
    btnFontSize:20,
    btnBorderRadius:5,
    ButtonGradient: true,
};
export default function formStyleReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_FORMS_STYLE: {
            const {frmWidth, brdWidth, brdColor, brdRadius} = action.payload;
            return {
                ...state,
                frmWidth: frmWidth,
                brdWidth: brdWidth,
                brdColor: brdColor,
                brdRadius: brdRadius,
            };
        }
        case ADD_FORMS_TEXT_STYLE: {
            const {textSize, textColor, titleSize, titleColor} = action.payload;
            return {
                ...state,
                titleColor: titleColor,
                titleSize: titleSize,
                textColor: textColor,
                textSize: textSize
            };
        }
        case ADD_FORMS_FIELDS_STYLE: {
            const {filedBorderColor, filedBorderRadius, fieldBorderWidth, fieldBgColor, fieldTextColor} = action.payload;
            return {
                ...state,
                filedBorderColor: filedBorderColor,
                filedBorderRadius: filedBorderRadius,
                fieldBorderWidth: fieldBorderWidth,
                fieldBgColor: fieldBgColor,
                fieldTextColor: fieldTextColor
            };
        }
        case ADD_FORMS_BUTTON_STYLE: {
            const {btnTextColor, btnBgColor, btnFontSize, btnBorderRadius,ButtonGradient} = action.payload;
            return {
                ...state,
                btnTextColor: btnTextColor,
                btnBgColor: btnBgColor,
                btnFontSize: btnFontSize,
                btnBorderRadius: btnBorderRadius,
                ButtonGradient:ButtonGradient
            };
        }
        default:
            return state;
    }
}
