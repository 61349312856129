import types from './sending_domain.actionTypes';

const initial_state = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    pending: true,
    errors: null,
    limit: 10,
    offset: 0,
}

export function sendingDomainReducer(state = initial_state, action) {
    const {payload, type} = action;
    switch (type) {
        case types.FETCH_SENDING_DOMAINS_START:
        case types.VALIDATE_SENDING_DOMAINS_START:
        case types.CREATE_SENDING_DOMAINS_START:
        case types.REMOVE_SENDING_DOMAINS_START:
            return {
                ...state,
                pending: true,
                errors: null,
            }
        case types.FETCH_SENDING_DOMAINS_SUCCESS:
            return {
                ...state,
                ...payload,
                pending: false,
            }
        case types.VALIDATE_SENDING_DOMAINS_SUCCESS:
            return {
                 ...state,
                pending: false,
                results: state.results.map(item => item.id === payload.id ? payload : item),
            }
        case types.CREATE_SENDING_DOMAINS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: [...state.results, payload]

            }
        case types.REMOVE_SENDING_DOMAINS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(result => result.id !== payload)
            }
        case types.FETCH_SENDING_DOMAINS_FAILURE:
        case types.VALIDATE_SENDING_DOMAINS_FAILURE:
        case types.CREATE_SENDING_DOMAINS_FAILURE:
        case types.REMOVE_SENDING_DOMAINS_FAILURE:
            return {
                ...state,
                pending: false,
                errors: payload,
            }
        case types.NEXT_PAGE:
            return {
                ...state,
                offset: state.offset + state.limit,
            }
        case types.PRE_PAGE:
            return {
                ...state,
                offset: state.offset - state.limit,
            }
        case types.SET_LIMIT:
            return {
                ...state,
                limit: payload,
                offset: 0,
            }
        default:
            return state
    }

}

export default sendingDomainReducer