export const FETCH_SUBSCRIBERS_PENDING = 'FETCH_SUBSCRIBERS_PENDING';
export const FETCH_SUBSCRIBERS_SUCCESS = 'FETCH_SUBSCRIBERS_SUCCESS';
export const FETCH_SUBSCRIBERS_ERROR = 'FETCH_SUBSCRIBERS_ERROR';

export const UPDATE_SUBSCRIBERS_PENDING = 'UPDATE_SUBSCRIBERS_PENDING';
export const UPDATE_SUBSCRIBERS_SUCCESS = 'UPDATE_SUBSCRIBERS_SUCCESS';
export const UPDATE_SUBSCRIBERS_ERROR = 'UPDATE_SUBSCRIBERS_ERROR';

export const ADD_SUBSCRIBER_PENDING = 'ADD_SUBSCRIBER_PENDING';
export const ADD_SUBSCRIBER_SUCCESS = 'ADD_SUBSCRIBER_SUCCESS';
export const ADD_SUBSCRIBER_ERROR = 'ADD_SUBSCRIBER_ERROR';

export const DELETE_SUBSCRIBER_PENDING = 'DELETE_SUBSCRIBER_PENDING';
export const DELETE_SUBSCRIBER_SUCCESS = 'DELETE_SUBSCRIBER_SUCCESS';
export const DELETE_SUBSCRIBER_ERROR = 'DELETE_SUBSCRIBER_ERROR';

export const SUBSCRIBER_CLEAN = 'SUBSCRIBER_CLEAN';